import { UtilService } from './../../../shared/services/util.service';
import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ConstantsService } from "../../../shared/services/constants.service";
import { HttpServiceService } from "../../../shared/services/http-service.service";
// import { CommonService } from "../../../shared/services/common.service";
import {
    NgbModal,
    NgbActiveModal,
    NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: "app-SendOCLink",
    templateUrl: "./SendOCLink.component.html",
    styleUrls: ["./SendOCLink.component.css"]
})
export class SendOCLinkComponent implements OnInit {
    @Input() data;
    mobileNumber="";
    alterNumber="";
    showSpinner=false;
    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService,
        public common : CommonService
    ) {}

    ngOnInit() {
        this.fetchMobNumber()
    }

    fetchMobNumber(){
        this.http.postData(this.constants.forwardingApi, this.common.getForwardApiName("parse", "getMobileNumber", {
            actorType:this.data['actortype'],
            actorId: this.data['actortype'] === 'Patient' ? this.data["customer"]["leadId"] : this.data["customer"]["DocId"],
            "source": "CXDashboard"
        }))
        .then(res=>{
            if(res["data"] && res["data"]["result"]["status"] === 200){
                this.mobileNumber = res["data"]["result"]["result"]
            }
        })
    }

    submitFollowUp(){
        if(!this.mobileNumber){
            this.toastr.error("Please Enter Mobile Number")
            return;
        }
        if(this.mobileNumber){
            if(this.mobileNumber.length !== 10) {
                this.toastr.error("Please Enter Valid Mobile Number")
                return;
            }
         }
        if(this.alterNumber){
           if(this.alterNumber.length !== 10) {
               this.toastr.error("Please Enter Valid Alternate Mobile Number")
               return;
           }
        }
        let reqBody = {
            leadId:this.data["customer"]["leadId"], 
            appId:this.data["customer"]["appId"], 
            hitOnDate:new Date().toISOString(), 
            hitByUserId:this.common.userId, 
            actorType:this.data['actortype'],
            mobileNumber:this.alterNumber ? this.alterNumber : this.mobileNumber,
            doctorId:this.data["customer"]["DocId"]
        }
        this.showSpinner = true
        this.http.postData(this.constants.forwardingApi, this.common.getForwardApiName("parse", "manuallySendOCLink", reqBody))
        .then(res=>{
            this.showSpinner = false
            if(res["data"] && res["data"]["result"]["status"] === 200){
                this.toastr.success(`OC Link Successfully Send to ${this.data['actortype']}`)
                this.activeModal.close()
            }else{
                this.toastr.error("Something Went Wrong")
            }
        })
        .catch(err=>{
            this.showSpinner = false
            this.toastr.error("Something Went Wrong")
        })
    }
}
